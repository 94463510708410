/* THIS FILE IS AUTO-GENERATED. DO NOT EDIT BY HAND! */

/* These all come from Trix / ActionText. This should probably be cleaned up into
   a regular .css for users to include.
*/

.trix-content {
  border: 1px solid var(--rhino-border-color);
  border-radius: 0px 0px var(--rhino-border-radius) var(--rhino-border-radius);
  margin: 0;
  padding: 0.4em 0.6em;
  min-height: 200px;
  outline: transparent;
  overflow-wrap: break-word;
  word-break: break-word;
}

.trix-content[contenteditable="true"] {
  /* Required by Prosemirror / TipTap */
  white-space: pre-wrap;
}

.trix-content:is(:focus, :focus-within) {
  border-color: var(--rhino-button-active-border-color);
}

.trix-content :is(img, svg, figure) {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.trix-content :is(figure, p) {
  padding: 0;
  margin: 0;
}

.trix-content figure {
  position: relative;
}

.trix-content figcaption {
  line-break: anywhere;
  display: inline-block;
  white-space: normal;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  text-align: center;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* If you use white-space: normal; in firefox you cant add blank space to the figcaption */
.trix-content[contenteditable="true"] figcaption {
  white-space: break-spaces;
}

.trix-content .rhino-upload-error {
  background-color: rgba(255, 0, 0, 0.3);
}

/* Attachments */
.trix-content:not([readonly])
  .attachment--preview:is(:focus-within, :focus, .has-focus)
  :is(img) {
  outline: transparent;
  box-shadow: var(--rhino-focus-ring);
}

.trix-content:not([readonly])
  .attachment:not(.attachment--preview):is(:focus-within, :focus, .has-focus) {
  outline: transparent;
  box-shadow: var(--rhino-focus-ring);
}

.rhino-attachment-editor::part(delete-button),
.rhino-attachment-editor::part(file-metadata) {
  display: none;
}

.trix-content:not([readonly])
  figure:is(:focus-within, :focus, .has-focus)
  .rhino-attachment-editor::part(delete-button),
.trix-content:not([readonly])
  figure:is(:focus-within, :focus, .has-focus)
  .rhino-attachment-editor::part(file-metadata) {
  display: flex;
}

.trix-content .placeholder {
  position: absolute;
  pointer-events: none;
  color: var(--rhino-placeholder-text-color);
  color: light-dark(
    var(--rhino-placeholder-text-color),
    var(--rhino-dark-placeholder-text-color)
  );
  cursor: text;
  content: "";
}
.trix-content figure.attachment figcaption {
  position: relative;
}

.trix-content p.is-editor-empty:first-child::before,
.trix-content
  figure[data-trix-attachment].has-focus
  figcaption.is-empty::before {
  color: var(--rhino-placeholder-text-color);
  color: light-dark(
    var(--rhino-placeholder-text-color),
    var(--rhino-dark-placeholder-text-color)
  );
  content: attr(data-placeholder);
  pointer-events: none;
}

.trix-content
  figure[data-trix-attachment]:not(.has-focus)
  figcaption.is-empty::before {
  content: attr(data-default-caption);
  pointer-events: none;
}

.trix-content ul {
  list-style: disc;
}

.trix-content
  figure[data-trix-attachment].has-focus
  figcaption.is-empty::before {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0%);
}

.trix-content p.is-editor-empty:first-child::before {
  float: left;
  height: 0;
  pointer-events: none;
}

.trix-content {
  box-sizing: border-box;
}
.trix-content * {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
.trix-content h1 {
  font-size: 1.2em;
  line-height: 1.2;
}
.trix-content blockquote {
  border: 0 solid #ccc;
  border-inline-start-width: 0.3em;
  margin-inline-start: 0.3em;
  padding-inline-start: 0.6em;
}
.trix-content li {
  margin-inline-start: 1em;
}

/** This is for `` inline code code elements. */
.trix-content :not(pre) code {
  background-color: #eee;
  border-radius: 2px;
  padding: 2px;
  margin: 0 1px;
  border: 1px solid rgba(192, 192, 192, 0.5);
  display: inline;
}

.trix-content code,
.trix-content pre {
  box-decoration-break: clone;
}

.trix-content pre {
  display: block;
  width: 100%;
  font-family: monospace;
  padding: 0.5em;
  white-space: pre;
  overflow-x: auto;
  background-color: #eee;
  font-size: 0.9em;
}

.trix-content img {
  max-width: 100%;
  height: auto;
}
.trix-content .attachment {
  display: block;
  position: relative;
  max-width: 100%;
}

/** Overrides Trix's link CSS */
.trix-content .attachment figcaption a,
.trix-content .attachment figcaption a:hover {
  color: LinkText;
}

/** Overrides Trix's link CSS */
.trix-content .attachment figcaption a:visited,
.trix-content .attachment figcaption a:hover:visited {
  color: VisitedText;
}

.trix-content .attachment__caption {
  text-align: center;
  margin-top: 0.5em;
}
.trix-content
  .attachment__caption
  .attachment__name
  + .attachment__size::before {
  content: " · ";
}
.trix-content .attachment--preview {
  width: 100%;
  text-align: center;
  margin: 0.6em 0;
}
.trix-content .attachment--preview .attachment__caption {
  color: #666;
  font-size: 0.9em;
  line-height: 1.2;
}
.trix-content .attachment.attachment--file {
  color: #333;
  line-height: 1;
  margin: 0 2px 2px 2px;
  padding: 0.4em 1em;
  border: 1px solid var(--rhino-border-color);
  border-radius: var(--rhino-border-radius);
}
.trix-content .attachment-gallery {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

/* For if galleries are disabled */
.trix-content figure.attachment {
  display: inline-block;
  position: relative;
  white-space: normal;
}

/* This is a cheap easy way to allow us to show <p> tags in galleries */
.trix-content .attachment-gallery > p {
  width: 0px;
  padding: 0;
  flex-basis: 0%;
  max-width: 0%;
  flex-shrink: 1;
  flex-grow: 0;
}

.trix-content .attachment-gallery .attachment {
  flex: 1 0 100%;
  padding: 0 0.5em;
  max-width: 100%;
  white-space: normal;
}

.trix-content
  .attachment-gallery
  > :is(.attachment--preview, action-text-attachment[previewable="true"]) {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%;
  white-space: normal;
}

/* 1 / 3 / n item[s] */
.trix-content
  .attachment-gallery
  > :is(.attachment, action-text-attachment):first-of-type:nth-last-of-type(1)
  ~ :is(.attachment, action-text-attachment),
.trix-content
  .attachment-gallery
  > :is(.attachment, action-text-attachment):first-of-type:nth-last-of-type(1) {
  padding: 0;
  flex-basis: 100%;
  max-width: 100%;
}

/* 2 items / 4 items */
.attachment-gallery
  > :is(
    .attachment--preview,
    action-text-attachment[previewable="true"]
  ):first-of-type:nth-last-of-type(2),
.attachment-gallery
  > :is(
    .attachment--preview,
    action-text-attachment[previewable="true"]
  ):first-of-type:nth-last-of-type(2)
  ~ :is(.attachment--preview, action-text-attachment[previewable="true"]),
.attachment-gallery
  > :is(
    .attachment--preview,
    action-text-attachment[previewable="true"]
  ):first-of-type:nth-last-of-type(4),
.attachment-gallery
  > :is(
    .attachment--preview,
    action-text-attachment[previewable="true"]
  ):first-of-type:nth-last-of-type(4)
  ~ :is(.attachment--preview, action-text-attachment[previewable="true"]) {
  flex-basis: 50%;
  max-width: 50%;
}

/** This carry over from Trix's gallery for backwards compat */
.trix-content
  .attachment-gallery.attachment-gallery--2
  action-text-attachment
  > .attachment,
.trix-content
  .attachment-gallery.attachment-gallery--4
  action-text-attachment
  > .attachment,
.trix-content .attachment-gallery action-text-attachment > .attachment {
  max-width: 100%;
}

        /* src/exports/styles/editor.js:hostStyles */

  :host,
  .trix-content {
    /* General tokens */
    --rhino-focus-ring: 0px 0px 1px 1px var(--rhino-button-active-border-color);
    --rhino-border-radius: 4px;

    --rhino-danger-border-color: red;
    --rhino-danger-background-color: #ffdddd;

    /* Editor tokens */
    --rhino-text-color: #374151;
    --rhino-dark-text-color: white;

    --rhino-border-color: #cecece;
    --rhino-placeholder-text-color: #cecece;
    --rhino-dark-placeholder-text-color: gray;

    /* Regular buttons */
    --rhino-button-text-color: #889;
    --rhino-button-dark-text-color: #eee;
    --rhino-button-border-color: #cecece;

    /** Disabled Buttons */
    --rhino-button-disabled-text-color: #d1d5db;
    --rhino-button-disabled-border-color: #d1d5db;
    --rhino-button-disabled-background-color: #d1d5db;

    /** Active buttons */
    --rhino-button-active-border-color: #005a9c;
    --rhino-button-active-background-color: rgb(226 239 255);

    --rhino-toolbar-text-color: hsl(219, 6%, 43%);
    --rhino-toolbar-icon-size: 1em;

    --rhino-dialog-border-color: hsl(
      var(--rhino-button-focus-background-color-hsl) / 50%
    );

    /** Focus buttons */
    --rhino-button-focus-background-color: hsl(
      var(--rhino-button-focus-background-color-hsl)
    );

    --rhino-button-focus-background-color-hsl: 219 26% 95%;

    /**
     * Override "--rhino-fake-selection-color" to change the color of .rhino-selection when the editor is not focused.
     */
    --rhino-fake-selection-color: rgb(220, 220, 220);

    display: block;

    color: var(--rhino-text-color);
    color: light-dark(var(--rhino-text-color), var(--rhino-dark-text-color));
  }


/* src/exports/styles/editor.js:toolbarButtonStyles */

  /**
 * Cursor styles that are useful for providing a more "pleasant" editor experience.
 */
  /**
* https://github.com/curvenote/editor/blob/main/packages/prosemirror-codemark/src/codemark.css
*/
  @keyframes rhino-blink {
    49% {
      border-color: unset;
    }
    50% {
      border-color: Canvas;
    }
    99% {
      border-color: Canvas;
    }
  }
  .rhino-editor .no-cursor {
    caret-color: transparent;
  }

  :where(.rhino-editor) .fake-cursor {
    margin: 0;
    padding: 0;
    margin-right: -1px;
    border-left-width: 1px;
    border-left-style: solid;
    animation: rhino-blink 1s;
    animation-iteration-count: infinite;
    position: relative;
    z-index: 1;
  }

  /** This is for actual "selection" which are highlighting more than 1 character. */
  :where(.rhino-editor .ProseMirror):not(:focus-within) .rhino-selection {
    background: var(--rhino-fake-selection-color);
  }

  .ProseMirror-separator {
    display: none !important;
  }


/* src/exports/styles/editor.js:toolbarButtonStyles */

  .rhino-toolbar-button {
    appearance: none;
    -webkit-appearance: none;
    border: 1px solid var(--rhino-border-color);
    border-radius: var(--rhino-border-radius);
    padding: 0.4em;
    color: var(--rhino-button-text-color);
    color: light-dark(
      var(--rhino-button-text-color),
      var(--rhino-button-dark-text-color)
    );
    background: Canvas;
    font-size: inherit;
    display: inline-grid;
  }

  .rhino-toolbar-button:is([aria-disabled="true"], :disabled) {
    color: var(--rhino-button-disabled-text-color);
    border-color: var(--rhino-button-disabled-border-color);
  }

  .rhino-toolbar-button[aria-disabled="true"]:focus {
    border-color: var(--rhino-button-disabled-border-color);
  }

  .rhino-toolbar-button svg {
    min-height: var(--rhino-toolbar-icon-size);
    min-width: var(--rhino-toolbar-icon-size);

    /* max-height / max-width needs to be set for safari */
    max-height: var(--rhino-toolbar-icon-size);
    max-width: var(--rhino-toolbar-icon-size);
  }

  .rhino-toolbar-button:is(:focus, :hover):not(
      [aria-disabled="true"],
      :disabled
    ) {
    outline: transparent;
    border-color: var(--rhino-button-active-border-color);
  }

  .rhino-toolbar-button:is(:focus):not([aria-disabled="true"], :disabled) {
    box-shadow: var(--rhino-focus-ring);
  }

  /* Only change the background color in certain scenarios */
  .rhino-toolbar-button:is(:hover):not(
      [aria-disabled="true"],
      :disabled,
      [aria-pressed="true"],
      [part~="toolbar__button--active"]
    ) {
    background-color: var(--rhino-button-focus-background-color);
    background-color: light-dark(
      var(--rhino-button-focus-background-color),
      gray
    );
  }

  .rhino-toolbar-button:is([aria-disabled="true"], :disabled):not(
      [part~="toolbar__button--active"]
    ) {
    color: var(--rhino-button-disabled-text-color);
    color: light-dark(var(--rhino-button-disabled-text-color), gray);
    border-color: var(--rhino-button-disabled-border-color);
  }

  .rhino-toolbar-button:is(:focus, :hover):is(
      [aria-disabled="true"],
      :disabled
    ):not([part~="toolbar__button--active"]) {
    outline: transparent;
    color: var(--rhino-button-disabled-text-color);
    color: light-dark(var(--rhino-button-disabled-text-color), gray);
    border-color: var(--rhino-button-disabled-border-color);
    box-shadow: 0 0 0 1px var(--rhino-button-disabled-border-color);
    box-shadow: 0 0 0 1px
      light-dark(var(--rhino-button-disabled-border-color), transparent);
  }